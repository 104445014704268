import React from 'react';
import { Link } from "react-router-dom";

import constants from '../../lib/constants';

function ListItemRecord({giftCard}){
    return (
        <div className="list_content_item item-record">
            <Link to="/"className='d-flex align-items-center' onClick={(e) => e.preventDefault()}>
                <div className="list_content_pic"><img src={`${constants.imageBaseUrl}/${giftCard.image}`} alt="" /></div>
                <div className="list_content_text">
                    <p className="list_content_title">{giftCard.seriesName}</p>
                    <p className="list_content_inform">{giftCard.redeemAt}</p>
                    <p className="list_content_inform">序號：{giftCard.serialNumber}</p>
                </div>
            </Link>
        </div>
    );
}

export default ListItemRecord;